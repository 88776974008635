import { Box, BoxProps, SxProps, Theme, alpha, styled } from "@mui/material";

export const StyledContainer = styled(Box)<BoxProps>(() => ({
  height: "100vh",
  width: "100vw",
  position: "absolute",
  zIndex: 999999999,
  top: 0,
  left: 0,
}));

export const StyledLoader = styled(Box)<BoxProps>(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: theme.zIndex.drawer + 2,
}));

export const StyledBackDrop = styled(Box)<BoxProps>(({ theme }) => ({
  minHeight: "100vh",
  height: "100%",
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  backdropFilter: "blur(2px)",
  backgroundColor: alpha(theme.palette.common.white, 0.5),
  zIndex: theme.zIndex.drawer + 1,
}));

export const backdropSx: SxProps<Theme> = theme => ({
  backdropFilter: "blur(2px)",
  backgroundColor: alpha(theme.palette.common.white, 0.5),
  zIndex: theme.zIndex.drawer + 1,
});
