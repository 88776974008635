import { Theme, lighten, SxProps } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
    },
    summaryContainer: {
      p: theme.spacing(2),
      backgroundColor: lighten(theme.palette.atmosphere?.main ?? "", 0.9),
      borderWidth: 1,
      borderStyle: "dotted",
      borderColor: theme.palette.atmosphere?.main,
    },
    crumIconContainer: {
      borderRadius: theme.spacing(1),
      height: 30,
      width: 30,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }),
);

export const containerSx: SxProps<Theme> = theme => ({
  backgroundColor: theme.palette.common.white,
  boxShadow: theme.shadows[24],
  padding: {
    xs: theme.spacing(2),
    md: theme.spacing(3),
  },
  borderRadius: "10px",
  // width: '100%'
});

export const secondaryTextSx: SxProps = () => ({
  width: {
    xs: "70%",
    sm: "80%",
    md: "90%",
    xl: "100%",
  },
});

export const listItemSx: SxProps = () => ({
  px: 0,
  "& .MuiListItemSecondaryAction-root": {
    right: {
      xs: 0,
      md: 16,
    },
  },
});

export const listItemFileSx: SxProps = () => ({});

export const paymentSummarySx: SxProps<Theme> = theme => ({
  backgroundColor: lighten(theme.palette.atmosphere?.main ?? "", 0.9),
  borderWidth: 1,
  borderStyle: "dotted",
  borderColor: theme.palette.atmosphere?.main,
});

export const actionButtonIconSx: SxProps<Theme> = theme => ({
  fill: theme.palette.atmosphere?.main,
});

export const alertSx: SxProps<Theme> = theme => ({
  alignItems: "center",
  "&.MuiAlert-standard.MuiAlert-standardSuccess": {
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.success?.main,
  },
});
