import { ThemeProvider } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import GlobalLoader from "@/components/GlobalLoader";
import GlobalModal from "@/components/GlobalModal";
import { useNavigation, useSession } from "@/hooks";
import { theme } from "@/theme";

const Root: React.FC = () => {
  const navigation = useNavigate();
  const { navigationSt } = useNavigation();
  const { activeSession, resetPassword } = navigationSt;
  const { endSession, sessionRoleSt } = useSession();

  useEffect(() => {
    if (activeSession && !resetPassword) {
      if (sessionRoleSt === "USER") {
        navigation("dashboard/v1/create-document");
      }
      if (sessionRoleSt === "ADMIN") {
        navigation("dashboard/v1/admin");
      }
    } else {
      endSession();
      navigation("session/login");
    }
  }, [activeSession, resetPassword]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalLoader />
      <GlobalModal />
      <Outlet />
    </ThemeProvider>
  );
};

export default Root;
